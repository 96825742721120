import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const CloseDialog = props => {
    const handleClose = () => { props.setOpen(false) };

    return (
        <Dialog open={props.open} onClose={handleClose} PaperProps={{ style: { backgroundColor: "#202a44", padding: 30 } }}>
            <DialogTitle color="#d9d9d9">Confirm Close</DialogTitle>
            <DialogContent><DialogContentText color="#d9d9d9">Are you sure you want to close the dialog?</DialogContentText></DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={{ backgroundColor: "transparent", color: "#d9d9d9", "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.5)" } }}>
                    Cancel
                </Button>
                <Button onClick={props.handleClose} sx={{ backgroundColor: "#1b5e7d", color: "#d9d9d9", "&:hover": { backgroundColor: "#1b5e7d" } }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CloseDialog;