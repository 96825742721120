import { Grid, Button, Chip, Autocomplete, TextField, Typography, Box, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem, Link, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import AddCheckoutModal from "./AddCheckoutModal";
import TheSnackbar from "../Common/TheSnackbar";
import { UserContext } from "../../context/UserContext";
import { getDaysAgo } from "../../utils/utils";
import PrintIcon from "@mui/icons-material/Print";
import EditCheckoutModal from "./EditCheckoutModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { axiosInstance } from "../../utils/utils";

const CheckoutsTable = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [rowCount, setRowCount] = useState(0);
    const [openAddCheckoutModal, setOpenAddCheckoutModal] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [rowCountState, setRowCountState] = useState(rowCount);
    const [paginationModel, setPaginationModel] = useState({
        page: userContext.checkoutsPage ? userContext.checkoutsPage : 0,
        pageSize: window.localStorage.getItem("checkoutsPageSize") !== null ? parseInt(window.localStorage.getItem("checkoutsPageSize")) : 15
    });
    const [data, setData] = useState([]);
    const [sort, setSort] = useState("");
    const [search, setSearch] = useState(userContext.projectsTableSearch ? userContext.projectsTableSearch : "");
    const chips = ["In Progress", "Completed"];
    const [selectedChips, setSelectedChips] = useState({ "In Progress": true, "Completed": false });
    const [checkoutId, setCheckoutId] = useState();
    const [openEditCheckoutModal, setOpenEditCheckoutModal] = useState(false);
    const [filterDates, setFilterDates] = useState({ startDate: null, endDate: null });

    const navigate = useNavigate();

    const handleDateKeyDown = (event, name) => {
        if (event.key === "Enter") {
            event.preventDefault();
            setFilterDates({ ...filterDates, [name]: dayjs(event.target.value) });
        }
    };

    const handleClickEntry = (event, _id) => {
        if (event.target.tagName === "INPUT"
            || event.target.tagName === "H6"
            || event.target.tagName === "SPAN"
            || event.target.tagName === "LABEL"
            || event.target.tagName === "A") {

            return;
        }

        navigate("/checkouts/" + _id);
    };

    const handleClickCompleted = (event, _id) => {
        const index = data.findIndex(checkout => checkout._id === _id);
        const updatedData = [...data];
        updatedData[index].completed = event.target.checked;
        setData(updatedData);

        const url = process.env.REACT_APP_API_URL + "/checkouts/" + _id;
        const payload = { _id: _id, completed: event.target.checked };
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
            })
            .catch(err => {
                // Do nothing
            });
    };

    const handlePageChange = newPaginationModel => {
        setPaginationModel(newPaginationModel);
        window.localStorage.setItem("checkoutsPageSize", newPaginationModel.pageSize);
        setUserContext(userContext => ({ ...userContext, checkoutsPage: newPaginationModel.page }));

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    };

    const getImage = image => {
        if (!image) {
            return null;
        }

        return process.env.REACT_APP_API_URL + "/images/" + image + "?token=" + userContext.token;
    };

    useEffect(() => {
        const fetchData = () => {
            if (paginationModel.pageSize === 0) {
                return;
            }

            let url = process.env.REACT_APP_API_URL + "/checkouts?page=" + paginationModel.page + "&limit=" + paginationModel.pageSize;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (sort) {
                url += "&sort=" + sort;
            }

            if (selectedChips["In Progress"] && !selectedChips["Completed"]) {
                url += "&completed=" + false;
            } else if (selectedChips["Completed"] && !selectedChips["In Progress"]) {
                url += "&completed=" + true;
            }

            if (search) {
                url += "&search=" + search;
            }

            if (filterDates.startDate !== null && filterDates.endDate !== null) {
                const startDate = filterDates.startDate.toISOString().substring(0, 10);
                const endDate = filterDates.endDate.toISOString().substring(0, 10);

                url += "&start_date=" + startDate + "&end_date=" + endDate;
            }

            axiosInstance.get(url, config)
                .then(res => {
                    setData(res.data.checkouts);
                    setRowCount(res.data.total);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchData();
    }, [userContext, setUserContext, sort, selectedChips, search, paginationModel, filterDates]);

    useEffect(() => {
        setRowCountState(prevRowCountState => rowCount !== undefined ? rowCount : prevRowCountState);
    }, [rowCount, setRowCountState]);

    const handleOpenEdit = (event, _id) => {
        setCheckoutId(_id);
        setOpenEditCheckoutModal(true);
    };

    const rows = data;

    const columns = [{
        field: "_id",
        headerName: "Checkout",
        flex: 1,
        renderCell: params => {
            return (
                <Grid container direction="row" justifyContent="space-between" spacing={2.5} onClick={event => handleClickEntry(event, params.row._id)}>
                    <Grid container item direction="column" xs={6}>
                        <Grid item>
                            <Typography variant="h6" sx={{ cursor: "pointer", width: "fit-content" }}>
                                <Link onClick={event => navigate("/checkouts/" + params.row._id)} href={"/checkouts/" + params.row._id} sx={{ textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                                    {params.row.name}
                                </Link>
                            </Typography>
                            <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content" }}>
                                <b>Date: </b>{new Date(params.row.date).toLocaleDateString()} {new Date(params.row.date).toLocaleTimeString()}
                            </Typography>
                            {params.row.company &&
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content" }}>
                                    <b>Company: </b>{params.row.company}
                                </Typography>
                            }
                            {params.row.project &&
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content" }}>
                                    <b>Project: </b>{params.row.project}
                                </Typography>
                            }
                            {params.row.notes &&
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content" }}>
                                    <b>Notes: </b>{params.row.notes}
                                </Typography>
                            }
                            {params.row.items?.length > 0 &&
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content" }}>
                                    <b>Items: </b>
                                    {params.row.items.map((item, index) => {
                                        if (index > 0) {
                                            return ", " + item.name;
                                        } else {
                                            return item.name;
                                        }
                                    })}
                                </Typography>
                            }
                            <Typography sx={{ cursor: "pointer", width: "fit-content" }}>
                                <Link onClick={event => handleOpenEdit(event, params.row._id)} sx={{ color: "rgba(0, 0, 0, 0.65)", textDecoration: "underline", "&:hover": { cursor: "pointer" } }}>
                                    Edit
                                </Link>
                            </Typography>
                            <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.5)", mt: "5px" }}>Updated {getDaysAgo(params.row.updatedAt)}</Typography>
                            <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.5)" }}>Created {new Date(params.row.createdAt).toDateString()}</Typography>
                        </Grid>
                        <Grid item>
                            <FormControlLabel checked={params.row.completed} control={<Checkbox />} onChange={event => handleClickCompleted(event, params.row._id)} label="Completed" />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                            <div style={{ width: "100%", height: "200px", maxWidth: "300px" }}>
                                {params.row.items?.some(item => item.image) &&
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <img
                                            src={getImage(process.env.REACT_APP_THUMBNAIL_PREFIX + params.row.items.find(item => item.image)?.image)}
                                            alt=""
                                            width="100%"
                                            height="100%"
                                            style={{ objectFit: "contain", objectPosition: "top right" }}
                                        />
                                    </div>
                                }
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }];

    const handleClickChip = chip => {
        const updatedSelectedChips = { ...selectedChips };

        for (const [key] of Object.entries(updatedSelectedChips)) {
            updatedSelectedChips[key] = key === chip;
        }

        setSelectedChips(updatedSelectedChips);
    };

    const handleSearch = event => {
        if (event.keyCode === 13) {
            setSearch(event.target.value);
        }
    };

    useEffect(() => {
        setUserContext(userContext => ({ ...userContext, projectsTableSearch: search }));
    }, [setUserContext, search]);

    const apiRef = useGridApiRef();

    const CustomToolbar = () =>
        <GridToolbarContainer
            sx={{
                "@media print": {
                    "& .css-1ct0wh-MuiGrid-root": { display: "none" },
                    "& .css-fie136-MuiGrid-root": { display: "none" },
                    "& .css-1v4wuvs-MuiGrid-root": { display: "none" },
                    "& .css-blursa-MuiGrid-root": { display: "flex" },
                    "& .MuiTypography-root": { fontFamily: "Arial" }
                }
            }}
        >
            <Grid container spacing={2.5} sx={{ marginBottom: "25px", display: "none" }}>
                <Grid item>
                    <Typography variant="h5">List of Checkouts</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4} alignItems="center" sx={{ marginBottom: "25px" }}>
                <Grid item>
                    <Autocomplete
                        options={[]}
                        renderInput={params => (<TextField {...params} label="Search" onKeyDown={handleSearch} />)}
                        freeSolo
                        value={search}
                        sx={{ width: 300 }}
                    />
                </Grid>
                {false && <Grid item><Divider orientation="vertical" sx={{ height: "56px" }} /></Grid>}
                {false && <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item>
                        <DatePicker
                            label="From:"
                            defaultValue={filterDates.startDate}
                            onAccept={value => setFilterDates({ ...filterDates, "startDate": value })}
                            sx={{ width: 170 }}
                            slotProps={{ textField: { onKeyDown: event => handleDateKeyDown(event, "startDate") } }}
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            label="To:"
                            defaultValue={filterDates.endDate}
                            onAccept={value => setFilterDates({ ...filterDates, "endDate": value })}
                            sx={{ width: 170 }}
                            slotProps={{ textField: { onKeyDown: event => handleDateKeyDown(event, "endDate") } }}
                        />
                    </Grid>
                </LocalizationProvider>}
            </Grid>
            <Grid container item alignItems="center" sx={{ marginBottom: "25px" }}>
                <Button
                    size="medium"
                    sx={{ marginRight: 2.5 }}
                    onClick={() => apiRef.current.exportDataAsPrint({ hideFooter: true })}
                >
                    <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><PrintIcon sx={{ fontSize: 20 }} /></span>
                    Print
                </Button>
                <Button
                    size="medium"
                    sx={{ backgroundColor: "#1b5e7d", color: "#d9d9d9", "&:hover": { backgroundColor: "#1b5e7d" } }}
                    onClick={() => setOpenAddCheckoutModal(true)}
                >
                    <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                    Checkout
                </Button>
            </Grid>
            <Grid container item spacing={2.5} alignItems="center" sx={{ marginBottom: "25px" }}>
                <Grid container item spacing={1.5} xs={8}>
                    {chips.map((chip, index) => {
                        return (
                            <Grid item key={index}>
                                <Chip label={chip} variant={selectedChips[chip] ? "filled" : "outlined"} onClick={() => handleClickChip(chip)} />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid container item xs={4} justifyContent="flex-end">
                    <FormControl fullWidth size="small" sx={{ maxWidth: "200px" }}>
                        <InputLabel>Sort by</InputLabel>
                        <Select
                            value={sort}
                            label="Sort by"
                            onChange={event => setSort(event.target.value)}
                        >
                            <MenuItem value={"name:asc"}>Name</MenuItem>
                            <MenuItem value={"updatedAt:desc"}>Last updated</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </GridToolbarContainer >

    return (
        <Grid container item>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                autoHeight
                disableRowSelectionOnClick
                disableColumnMenu
                getRowId={row => row._id}
                slots={{
                    toolbar: CustomToolbar,
                    noRowsOverlay: () => {
                        return <Typography variant="body1" align="center" sx={{ color: "#495464" }}>No checkout was added ;-(</Typography>;
                    }
                }}
                pageSizeOptions={[15, 25, 50, 100]}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={handlePageChange}
                rowCount={rowCountState}
                apiRef={apiRef}
                sx={{
                    fontSize: 16,
                    border: 0,
                    "& .MuiDataGrid-cell": {
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        border: 0,
                        "&:focus-within, &:focus": { outline: "none" }
                    },
                    "& .MuiDataGrid-footerContainer": { border: 0 },
                    "& .MuiDataGrid-columnHeaders": { display: "none" },
                    "& .MuiDataGrid-row:hover": { borderRadius: "0px" },
                    "& .MuiDataGrid-row": { borderBottom: "1px solid rgba(0, 0, 0, 0.1)", },
                    "@media print": {
                        fontFamily: "Arial",
                        color: "#000000",
                        "& .MuiDataGrid-main": { height: "inherit" },
                        "@page": { size: "landscape" }
                    }
                }}
            />
            {openAddCheckoutModal &&
                <AddCheckoutModal
                    openModal={openAddCheckoutModal}
                    setOpenModal={setOpenAddCheckoutModal}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                />
            }
            {openEditCheckoutModal &&
                <EditCheckoutModal
                    openModal={openEditCheckoutModal}
                    setOpenModal={setOpenEditCheckoutModal}
                    _id={checkoutId}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                />
            }
        </Grid>
    );
};

export default CheckoutsTable;