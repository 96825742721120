import TopBar from "../components/Layout/TopBar";
import { Grid } from "@mui/material";
import UserInfo from "../components/Settings/UserInfo";
import Users from "../components/Settings/Users";
import TheSnackbar from "../components/Common/TheSnackbar";
import { UserContext } from "../context/UserContext";
import { useState, useContext } from "react";

const Settings = () => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [userContext] = useContext(UserContext);

    return (
        <Grid container direction="column" sx={{ maxWidth: "1000px" }}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <TopBar title="Settings" enableBack="true" />
            </Grid>
            <Grid container item direction="column">
                <Grid container item>
                    <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                        <legend>User Info</legend>
                        <UserInfo setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} />
                    </fieldset>
                </Grid>
                {userContext.admin &&
                    <Grid container item sx={{ mt: 3 }}>
                        <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                            <legend>Users</legend>
                            <Users setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} />
                        </fieldset>
                    </Grid>
                }
            </Grid>
        </Grid >
    );
};

export default Settings;