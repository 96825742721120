import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: { main: "#202a44" },
        secondary: { main: "#1b5e7d" },
        error: { main: "#d71313" },
        text: { primary: "#202a44", secondary: "#1b5e7d" },
        background: { default: "#d9d9d9" }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#202a44",
                    color: "#d9d9d9",
                    "&:hover": { backgroundColor: "#202a44" },
                    "&:disabled": { backgroundColor: "#bbbfca" },
                    paddingLeft: "25px",
                    paddingRight: "25px"
                },
                outlined: {
                    backgroundColor: "transparent",
                    color: "#495464",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                    paddingLeft: "25px",
                    paddingRight: "25px"
                }
            }
        },
        MuiInputBase: { styleOverrides: { root: { color: "#000000" } } },
        MuiInputLabel: { styleOverrides: { root: { color: "#495464" } } },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: { background: "#302b27", color: "#d9d9d9" },
                option: { "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1) !important" } }
            }
        }
    }
});

export default theme;