import { Grid, Avatar, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { axiosInstance } from "../../utils/utils";

const TopBar = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [anchorElement, setAnchorElement] = useState();
    const openMenu = Boolean(anchorElement);

    const navigate = useNavigate();

    const handleOpenMenu = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorElement(null);
    };

    const handleLogOut = () => {
        const url = process.env.REACT_APP_API_URL + "/users/logout";
        const config = { withCredentials: true, headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.get(url, config)
            .then((res => {
                setUserContext({});
                window.localStorage.clear();
                window.localStorage.setItem("logout", Date.now());
                window.location = "/";
            }))
            .catch(err => { });
    };

    return (
        <>
            {userContext.username &&
                <Grid item container wrap="nowrap" sx={{ alignItems: "center", justifyContent: "space-between", maxWidth: "1000px", padding: "25px 0px 25px 0px", borderBottom: "1px solid black" }}>
                    <Grid container item xs={8} alignItems="center">
                        {props.enableBack &&
                            <Grid item sx={{ mr: 2.5 }}>
                                <IconButton onClick={() => navigate("/main")} sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                        }
                        {props.subtitle ?
                            <Grid item sx={{ mr: 2.5 }}>
                                <Typography variant="h6">{props.title}</Typography>
                                <Typography variant="h5">{props.subtitle}</Typography>
                            </Grid>
                            :
                            <Grid item sx={{ mr: 2.5 }}>
                                <Typography variant="h5">{props.title}</Typography>
                            </Grid>
                        }
                    </Grid>
                    <Grid container item xs={4} justifyContent="flex-end">
                        <IconButton onClick={handleOpenMenu} sx={{ "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" } }}>
                            <Avatar sx={{ bgcolor: "#202a44" }}>
                                {userContext.username.substring(0, 1).toUpperCase()}
                            </Avatar>
                        </IconButton>
                        <Menu anchorEl={anchorElement} open={openMenu} onClose={handleCloseMenu}>
                            <MenuItem onClick={() => navigate("/settings")}>Settings</MenuItem>
                            <MenuItem onClick={handleLogOut}>Log out</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default TopBar;