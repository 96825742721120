import { Grid, Button, Chip, Autocomplete, TextField, Typography, Box, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { DataGrid, GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import TheSnackbar from "../Common/TheSnackbar";
import { UserContext } from "../../context/UserContext";
import PrintIcon from "@mui/icons-material/Print";
import EditCheckoutModal from "./EditCheckoutModal";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ImageModal from "../Common/ImageModal";
import { axiosInstance } from "../../utils/utils";

const CheckoutTable = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [data, setData] = useState({});
    const [sort, setSort] = useState("");
    const [search, setSearch] = useState();
    const chips = ["Returned", "Not Returned"];
    const [selectedChips, setSelectedChips] = useState({ "Returned": true, "Not Returned": true });
    const [openEditCheckoutModal, setOpenEditCheckoutModal] = useState(false);
    const params = useParams();
    const [openImageModal, setOpenImageModal] = useState(false);
    const [image, setImage] = useState();
    const [filteredData, setFilteredData] = useState({});
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: window.localStorage.getItem("checkoutPageSize") !== null ? parseInt(window.localStorage.getItem("checkoutPageSize")) : 15
    });

    const handleClickReturned = (event, _id) => {
        const index = data.items.findIndex(item => item._id === _id);
        const updatedData = { ...data };
        updatedData.items[index].returned = event.target.checked;
        setData(updatedData);

        let url = process.env.REACT_APP_API_URL + "/items/" + _id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const payload = { returned: event.target.checked };

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                // Do nothing
            })
            .catch(err => {
                // Do nothing
            });
    };

    const getImage = image => {
        return process.env.REACT_APP_API_URL + "/images/" + image + "?token=" + userContext.token;
    };

    const setName = props.setName;
    const setDate = props.setDate;
    const setCompany = props.setCompany;
    const setProject = props.setProject;
    const setNotes = props.setNotes;

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + "/checkouts/" + params._id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setData(res.data);
                    setName(res.data.name);
                    setDate(new Date(res.data.date).toLocaleDateString() + " " + new Date(res.data.date).toLocaleTimeString());
                    setCompany(res.data.company);
                    setProject(res.data.project);
                    setNotes(res.data.notes);
                })
                .catch(err => {
                    // Do nothing
                });
        }

        fetchData();
    }, [userContext, setUserContext, sort, selectedChips, search, params._id, setCompany, setName, setNotes, setDate, setProject]);

    useEffect(() => {
        const filterData = () => {
            let updatedFilteredData = { ...data };

            if (!(selectedChips["Returned"] && selectedChips["Not Returned"])) {
                updatedFilteredData.items = updatedFilteredData.items.filter(item => {
                    if (selectedChips["Returned"]) {
                        return item.returned === true;
                    } else {
                        return item.returned === false;
                    }
                });
            }

            if (search) {
                updatedFilteredData.items = updatedFilteredData.items.filter(item => {
                    return item.name.toLowerCase().includes(search) || item.description.toLowerCase().includes(search);
                });
            }

            setFilteredData(updatedFilteredData);
        };

        filterData();
    }, [data, search, selectedChips])

    const rows = filteredData.items ? filteredData.items : [];

    const columns = [{
        field: "_id",
        headerName: "Item",
        flex: 1,
        renderCell: params => {
            return (
                <Grid container direction="row" justifyContent="space-between" spacing={2.5}>
                    <Grid container item direction="column" xs={6}>
                        <Grid item>
                            <Typography variant="h6" sx={{ cursor: "pointer", width: "fit-content" }}>
                                {params.row.name}
                            </Typography>
                            {params.row.description &&
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content" }}>
                                    <b>Description: </b>{params.row.description}
                                </Typography>
                            }
                            {params.row.quantity &&
                                <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content" }}>
                                    <b>Quantity: </b>{params.row.quantity}
                                </Typography>
                            }
                        </Grid>
                        <Grid item>
                            <FormControlLabel checked={params.row.returned} control={<Checkbox />} onChange={event => handleClickReturned(event, params.row._id)} label="Returned" />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                            <div style={{ width: "100%", height: "200px", maxWidth: "300px" }}>
                                {params.row.image &&
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <img
                                            src={getImage(process.env.REACT_APP_THUMBNAIL_PREFIX + params.row.image)}
                                            alt=""
                                            width="100%"
                                            height="100%"
                                            style={{ objectFit: "contain", objectPosition: "top right" }}
                                            onClick={() => handleClickImage(getImage(params.row.image))}
                                        />
                                    </div>
                                }
                            </div>

                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }];

    const handleClickChip = chip => {
        setSelectedChips({ ...selectedChips, [chip]: !selectedChips[chip] });
    };

    const handleSearch = event => {
        if (event.keyCode === 13) {
            setSearch(event.target.value.toLowerCase());
        }
    };

    const handleClickImage = imgSrc => {
        setImage(imgSrc);
    };

    const handlePageChange = newPaginationModel => {
        setPaginationModel(newPaginationModel);
        window.localStorage.setItem("checkoutPageSize", newPaginationModel.pageSize);

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    };

    useEffect(() => {
        if (image) {
            setOpenImageModal(true);
        }
    }, [image]);

    const apiRef = useGridApiRef();

    const CustomToolbar = () =>
        <GridToolbarContainer
            sx={{
                "@media print": {
                    "& .css-18jumuh-MuiGrid-root": { display: "none" },
                    "& .css-1ct0wh-MuiGrid-root": { display: "none" },
                    "& .css-4pkl32-MuiGrid-root": { display: "none" },
                    "& .css-blursa-MuiGrid-root": { display: "flex" },
                    "& .MuiTypography-root": { fontFamily: "Arial" }
                }
            }}
        >
            <Grid container spacing={2.5} sx={{ marginBottom: "25px", display: "none" }}>
                <Grid item>
                    <Typography variant="h6" sx={{ cursor: "pointer", width: "fit-content" }}>
                        {data.name}
                    </Typography>
                    <Typography>
                        <b>Date: </b>{new Date(data.date).toLocaleDateString()} {new Date(data.date).toLocaleTimeString()}
                    </Typography>
                    {data.company &&
                        <Typography>
                            <b>Company: </b>{data.company}
                        </Typography>
                    }
                    {data.notes &&
                        <Typography>
                            <b>Notes: </b>{data.notes}
                        </Typography>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2.5} alignItems="center" sx={{ marginBottom: "25px" }}>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Autocomplete
                        options={[]}
                        renderInput={params => (<TextField {...params} label="Search" onKeyDown={handleSearch} />)}
                        freeSolo
                        value={search}
                        sx={{ width: 300 }}
                    />
                </Grid>
            </Grid>
            <Grid item sx={{ marginBottom: "25px" }}>
                <Button
                    size="medium"
                    sx={{ marginRight: 2.5 }}
                    onClick={() => apiRef.current.exportDataAsPrint({ hideFooter: true })}
                >
                    <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><PrintIcon sx={{ fontSize: 20 }} /></span>
                    Print
                </Button>
                <Button
                    size="medium"
                    sx={{ backgroundColor: "#1b5e7d", color: "#d9d9d9", "&:hover": { backgroundColor: "#1b5e7d" } }}
                    onClick={() => setOpenEditCheckoutModal(true)}
                >
                    <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><EditIcon sx={{ fontSize: 20 }} /></span>
                    Edit
                </Button>
            </Grid>
            <Grid container item spacing={2.5} alignItems="center" sx={{ marginBottom: "25px" }}>
                <Grid container item spacing={1.5} xs={8}>
                    {chips.map((chip, index) => {
                        return (
                            <Grid item key={index}>
                                <Chip label={chip} variant={selectedChips[chip] ? "filled" : "outlined"} onClick={() => handleClickChip(chip)} />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid container item xs={4} justifyContent="flex-end">
                    <FormControl fullWidth size="small" sx={{ maxWidth: "200px" }}>
                        <InputLabel>Sort by</InputLabel>
                        <Select
                            value={sort}
                            label="Sort by"
                            onChange={event => setSort(event.target.value)}
                        >
                            <MenuItem value={"name:asc"}>Name</MenuItem>
                            <MenuItem value={"updatedAt:desc"}>Last updated</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </GridToolbarContainer >

    return (
        <Grid container item>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                autoHeight
                disableRowSelectionOnClick
                disableColumnMenu
                getRowId={row => row._id}
                slots={{
                    toolbar: CustomToolbar,
                    noRowsOverlay: () => {
                        return <Typography variant="body1" align="center" sx={{ color: "#495464" }}>No item was added ;-(</Typography>;
                    }
                }}
                pageSizeOptions={[15, 25, 50, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePageChange}
                apiRef={apiRef}
                sx={{
                    fontSize: 16,
                    border: 0,
                    "& .MuiDataGrid-cell": {
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        border: 0,
                        "&:focus-within, &:focus": { outline: "none" }
                    },
                    "& .MuiDataGrid-footerContainer": { border: 0 },
                    "& .MuiDataGrid-columnHeaders": { display: "none" },
                    "& .MuiDataGrid-row:hover": { borderRadius: "0px" },
                    "& .MuiDataGrid-row": { borderBottom: "1px solid rgba(0, 0, 0, 0.1)", },
                    "@media print": {
                        fontFamily: "Arial",
                        color: "#000000",
                        "& .MuiDataGrid-main": { height: "inherit" },
                        "@page": { size: "landscape" }
                    }
                }}
            />
            {openEditCheckoutModal &&
                <EditCheckoutModal
                    openModal={openEditCheckoutModal}
                    setOpenModal={setOpenEditCheckoutModal}
                    _id={params._id}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                />
            }
            {openImageModal &&
                <ImageModal
                    openModal={openImageModal}
                    setOpenModal={setOpenImageModal}
                    image={image}
                    setImage={setImage}
                />
            }
        </Grid>
    );
};

export default CheckoutTable;