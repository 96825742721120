import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const TheSnackbar = props => {
    const handleClose = () => props.setOpen(false);

    const action = <IconButton size="small" onClick={handleClose}><CloseIcon fontSize="small" sx={{ color: "#bbbfca" }} /></IconButton>;
    const message = <div style={{ display: "flex", alignItems: "center" }}>
        <CheckIcon size="small" sx={{ paddingRight: "10px" }} />{props.message}
    </div>

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={props.open}
            autoHideDuration={2000}
            message={message}
            onClose={handleClose}
            action={action}
            ContentProps={{ sx: { color: "#bbbfca" } }}
            sx={{ width: "50%", margin: "0 auto" }}
        />
    );
};

export default TheSnackbar;