import CheckoutTable from "../components/Checkouts/CheckoutTable";
import { Grid, Typography, IconButton, Box, Link } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useState, useContext, useRef } from "react";
import logo from "../images/Luu Marine.png";
import { UserContext } from "../context/UserContext";

const Checkout = () => {
    const navigate = useNavigate();
    const [name, setName] = useState();
    const [date, setDate] = useState();
    const [company, setCompany] = useState();
    const [project, setProject] = useState();
    const [notes, setNotes] = useState();
    const [userContext] = useContext(UserContext);
    const topRef = useRef(null);

    return (
        <Grid container item direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <Grid container item wrap="nowrap" alignItems="center" sx={{ padding: "25px 0px 25px 0px", mb: "25px", borderBottom: "1px solid black" }}>
                <Grid container item wrap="nowrap" alignItems="center" xs={6}>
                    {userContext.token &&
                        <Grid item sx={{ mr: 2.5 }}>
                            <IconButton onClick={() => navigate("/main")} sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                    }
                    <Grid item sx={{ mr: 2.5 }}>
                        <Typography variant="h5">{name}</Typography>
                        {date && <Typography variant="body1"><b>Date:</b> {date}</Typography>}
                        {company && <Typography variant="body1"><b>Company: </b> {company}</Typography>}
                        {project && <Typography variant="body1"><b>Project: </b> {project}</Typography>}
                        {notes && <Typography variant="body1"><b>Notes:</b> {notes}</Typography>}
                    </Grid>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }} compoent={Link} to="/main">
                    <Box display="flex" justifyContent="flex-end">
                        <img src={logo} onClick={() => navigate("/main")} alt="" style={{ height: "120px" }} />
                    </Box>
                </Grid>
            </Grid>
            <CheckoutTable setName={setName} setDate={setDate} setCompany={setCompany} setProject={setProject} setNotes={setNotes} topRef={topRef} />
        </Grid>
    );
};

export default Checkout;