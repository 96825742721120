import { Grid, Paper, Button, TextField, Typography, Modal, IconButton, Autocomplete } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useState, useEffect, useContext, useCallback } from "react";
import ErrorBox from "../Common/ErrorBox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteDialog from "../Common/DeleteDialog";
import { UserContext } from "../../context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CloseDialog from "../Common/CloseDialog";
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { combineDateTime } from "../../utils/utils";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { axiosInstance } from "../../utils/utils";

const EditCheckoutModal = props => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const detailsTextFieldParams = { type: "text", fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value) };
    const [openDelete, setOpenDelete] = useState(false);
    const [userContext, setUserContext] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [modified, setModified] = useState(false);
    const [openCloseDialog, setOpenCloseDialog] = useState(false);
    const location = useLocation();
    const [items, setItems] = useState([]);
    const itemTextFieldParams = { type: "text", fullWidth: true, variant: "outlined" };
    const [disableClearImageButtons, setDisableClearImageButtons] = useState([]);
    const [images, setImages] = useState([]);
    const [uploads, setUploads] = useState([]);
    const [itemsModified, setItemsModified] = useState(false);
    const [details, setDetails] = useState({ name: "", company: "", project: "", notes: "", date: dayjs(new Date()), time: dayjs(new Date()) });
    const [nameOptions, setNameOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [itemNameOptions, setItemNameOptions] = useState([]);

    useEffect(() => {
        const startDate = dayjs(new Date()).subtract(1, "month").toISOString().substring(0, 10);

        const fetchNameOptions = () => {
            const url = process.env.REACT_APP_API_URL + "/checkouts/autocomplete?type=name&start_date=" + startDate;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setNameOptions(res.data);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        const fetchCompanyOptions = () => {
            const url = process.env.REACT_APP_API_URL + "/checkouts/autocomplete?type=company&start_date=" + startDate;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setCompanyOptions(res.data);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        const fetchProjectOptions = () => {
            const url = process.env.REACT_APP_API_URL + "/checkouts/autocomplete?type=project&start_date=" + startDate;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setProjectOptions(res.data);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        const fetchItemNameOptions = () => {
            const url = process.env.REACT_APP_API_URL + "/checkouts/autocomplete?type=itemName&start_date=" + startDate;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setItemNameOptions(res.data);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchNameOptions();
        fetchCompanyOptions();
        fetchProjectOptions();
        fetchItemNameOptions();
    }, [userContext, setUserContext]);

    const handleDateChange = (name, value) => {
        if (!Date.parse(value)) {
            handleChange(name, "");
        } else {
            handleChange(name, value);
        }

        setModified(true);
    };

    const handleChange = (name, value) => {
        setDetails(pairs => ({ ...pairs, [name]: value }));
        setModified(true);
    };

    const handleClose = () => props.setOpenModal(false);

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return details.name && items.every(item => item.name);
        };

        setDisableSubmitButton(!fieldsNotEmpty());
    }, [details, items]);

    useEffect(() => {
        let updatedDisableClearImageButtons = [];

        items.forEach(item => {
            if (item.image) {
                updatedDisableClearImageButtons.push(false);
            } else {
                updatedDisableClearImageButtons.push(true);
            }
        });

        setDisableClearImageButtons(updatedDisableClearImageButtons);
    }, [items]);

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_URL + "/checkouts/" + props._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.delete(url, config)
            .then(res => {
                props.setOpenModal(false);

                if (location.pathname === "/main") {
                    props.setSnackbarMessage("Checkout deleted");
                    props.setOpenSnackbar(true);
                    setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
                } else {
                    navigate("/main", { replace: true, state: { snackbarMessage: "Checkout deleted" } });
                }
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    const getImage = useCallback(image => {
        return process.env.REACT_APP_API_URL + "/images/" + image + "?token=" + userContext.token;
    }, [userContext.token]);

    useEffect(() => {
        const fetchEntry = () => {
            let url = process.env.REACT_APP_API_URL + "/checkouts/" + props._id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    const date = res.data.date ? dayjs(res.data.date) : dayjs(new Date());

                    const fetchedDetails = res.data;

                    if (res.data.date) {
                        fetchedDetails.date = date;
                        fetchedDetails.time = date;
                    }

                    if (res.data.items) {
                        let fetchedImages = [];
                        let fetchedUploads = [];

                        fetchedDetails.items.forEach((item, index) => {
                            if (item.image) {
                                const fetchedImage = getImage(item.image);

                                fetchedImages.push(fetchedImage);

                                fetch(fetchedImage)
                                    .then(res => {
                                        res.blob().then(imgBlob => {
                                            const imgFile = new File([imgBlob], "image", { type: "image/jpeg" });
                                            fetchedUploads[index] = imgFile;
                                        });
                                    });

                                item.image = true;
                            } else {
                                fetchedImages.push(null);
                                fetchedUploads[index] = null;
                                item.image = false;
                            }
                        });

                        setImages(fetchedImages);
                        setUploads(fetchedUploads);
                        setDetails(details => ({ ...details, ...fetchedDetails }));
                        setItems(fetchedDetails.items);
                    }
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchEntry();
    }, [props._id, userContext, setUserContext, getImage]);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableSubmitButton(true);

        let url = process.env.REACT_APP_API_URL + "/checkouts/" + props._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const payload = { ...details };

        // Combine date and time
        const date = combineDateTime(details.date, details.time);

        payload.date = date;
        delete payload.time;

        if (!itemsModified) {
            delete payload.items;
        } else {
            if (items.length === 0) {
                payload.items = "";
            } else {
                payload.items = items;
            }
        }

        payload.images = uploads;

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                props.setOpenModal(false);
                props.setSnackbarMessage("Checkout modified");
                props.setOpenSnackbar(true);
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableSubmitButton(false);
            });
    };

    useEffect(() => setIsLoading(!details._id), [details]);

    const handleCloseWithChange = () => {
        if (modified) {
            setOpenCloseDialog(true);
        } else {
            props.setOpenModal(false)
        }
    };

    const handleItemChange = (name, value, index) => {
        let updatedItems = [...items];
        updatedItems[index][name] = value;
        setItems(updatedItems);
        setItemsModified(true);
        setModified(true);
    };

    const handleRemoveItem = index => {
        let updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);

        let updatedUploads = [...uploads];
        updatedUploads.splice(index, 1);
        setUploads(updatedUploads);

        let updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);

        setItemsModified(true);
        setModified(true);
    };

    const handleAddItem = () => {
        setItems(items => [...items, { name: "", description: "", quantity: 1, image: false }]);
        setImages(images => [...images, null]);
        setUploads(uploads => [...uploads, null]);
        setItemsModified(true);
        setModified(true);
    };

    const handleRemoveImage = index => {
        let updatedItems = [...items];
        updatedItems[index].image = false;
        setItems(updatedItems);

        let updatedUploads = [...uploads];
        updatedUploads[index] = null;
        setUploads(updatedUploads);

        let updatedImages = [...images];
        updatedImages[index] = null;
        setImages(updatedImages);

        setItemsModified(true);
        setModified(true);
    };

    const handleImageChange = (event, value, index) => {
        if (event.target.files.length === 0) return;

        let updatedItems = [...items];
        updatedItems[index].image = true;
        setItems(updatedItems);

        let updatedUploads = [...uploads];
        updatedUploads[index] = event.target.files[0];
        setUploads(updatedUploads);

        let updatedImages = [...images];
        updatedImages[index] = URL.createObjectURL(event.target.files[0]);
        setImages(updatedImages);

        setItemsModified(true);
        setModified(true);
    };

    return (
        <Modal open={props.openModal} onClose={handleCloseWithChange}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 800,
                    maxHeight: 700,
                    width: "90%",
                    height: "90%",
                    bgcolor: "#d9d9d9",
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 0" }}>
                    <IconButton onClick={handleCloseWithChange}><CloseIcon /></IconButton>
                </Grid>
                <div style={{ padding: "0% 6% 6% 6%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>Edit Checkout</Typography>
                    <Typography variant="body1" sx={{ mb: 2.5, color: "rgba(49, 54, 64, 0.6)" }}>Modify the selected checkout entry</Typography>
                    {!isLoading &&
                        <form onSubmit={handleSubmit}>
                            <Grid container direction="column" spacing={2.5}>
                                <Grid container item>
                                    <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                        <legend>Details</legend>
                                        <Grid container item spacing={4.5} sx={{ padding: "3%" }}>
                                            <Grid container item spacing={2.5}>
                                                <Grid item sx={{ width: "50%" }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Date"
                                                            value={details.date}
                                                            onChange={value => handleDateChange("date", value)}
                                                            sx={{ width: "100%" }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item sx={{ width: "50%" }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <TimePicker
                                                            label="Time"
                                                            value={details.time}
                                                            onChange={value => handleDateChange("time", value)}
                                                            sx={{ width: "100%" }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                            <Grid container item direction="column" spacing={2.5} sx={{ minWidth: "300px" }}>
                                                <Grid item>
                                                    <Autocomplete
                                                        options={nameOptions}
                                                        renderInput={params => (<TextField {...params} name="name" label="Name" value={details.name} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} />)}
                                                        onInputChange={(event, value) => handleChange("name", value)}
                                                        value={details.name}
                                                        freeSolo
                                                        fullWidth
                                                        disableClearable
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Autocomplete
                                                        options={companyOptions}
                                                        renderInput={params => (<TextField {...params} name="company" label="Company" value={details.company} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} />)}
                                                        onInputChange={(event, value) => handleChange("company", value)}
                                                        value={details.company}
                                                        freeSolo
                                                        fullWidth
                                                        disableClearable
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Autocomplete
                                                        options={projectOptions}
                                                        renderInput={params => (<TextField {...params} name="project" label="Project" value={details.project} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} />)}
                                                        onInputChange={(event, value) => handleChange("project", value)}
                                                        value={details.project}
                                                        freeSolo
                                                        fullWidth
                                                        disableClearable
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField {...detailsTextFieldParams} name="notes" label="Notes" multiline rows={4} value={details.notes} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid container item>
                                    <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                        <legend>Items</legend>
                                        <Grid container item direction="column" spacing={2.5} sx={{ padding: "3%" }}>
                                            <Grid container item spacing={2.5}>
                                                {items.length > 0 ?
                                                    items.map((item, index) => {
                                                        return (
                                                            <Grid container item key={index} spacing={4.5} justifyContent="center" sx={{ padding: "3%", marginBottom: "15px" }}>
                                                                <Grid container item spacing={2.5}>
                                                                    <Grid container item direction="column" justifyContent="center" xs={5} spacing={2.5} sx={{ minWidth: "265px" }}>
                                                                        <Grid container item justifyContent="center">
                                                                            <div style={{ width: "100%", height: "100%", maxWidth: "300px", aspectRatio: 1, borderRadius: "5px" }}>
                                                                                {item.image ?
                                                                                    <img src={images[index]} alt="" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "5px" }} />
                                                                                    :
                                                                                    <ImageIcon viewBox="3 3 18 18" sx={{ width: "100%", height: "100%" }} />
                                                                                }
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid container item justifyContent="center" spacing={1.5}>
                                                                            <Grid item>
                                                                                <label>
                                                                                    <input hidden type="file" accept="image/*" onChange={(event, value) => handleImageChange(event, value, index)} onClick={event => event.target.value = null} />
                                                                                    <Button color="secondary" variant="contained" component="span" sx={{ pl: 1, pr: 1 }}><FileUploadIcon /></Button>
                                                                                </label>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <label>
                                                                                    <input hidden type="file" capture="environment" accept="image/*" onChange={(event, value) => handleImageChange(event, value, index)} onClick={event => event.target.value = null} />
                                                                                    <Button color="secondary" variant="contained" component="span" sx={{ pl: 1, pr: 1 }}><PhotoCameraIcon /></Button>
                                                                                </label>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Button
                                                                                    onClick={() => handleRemoveImage(index)}
                                                                                    disabled={disableClearImageButtons[index]}
                                                                                    sx={{ backgroundColor: "#8da9b6", color: "#d9d9d9", pl: 1, pr: 1, "&:hover": { backgroundColor: "#8da9b6" } }}
                                                                                >
                                                                                    <CancelPresentationIcon />
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container item direction="column" justifyContent="center" xs={5} spacing={2.5} sx={{ minWidth: "265px" }}>
                                                                        <Grid item>
                                                                            <Autocomplete
                                                                                options={itemNameOptions}
                                                                                renderInput={params => (<TextField {...params} name="name" label="Name" value={item.name} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} />)}
                                                                                onInputChange={(event, value) => handleItemChange("name", value, index)}
                                                                                value={item.name}
                                                                                freeSolo
                                                                                fullWidth
                                                                                disableClearable
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TextField {...itemTextFieldParams} name="description" label="Description" multiline rows={4} value={String(item.description)} onChange={event => handleItemChange(event.target.name, event.target.value, index)} />
                                                                        </Grid>
                                                                        <Grid item><TextField {...itemTextFieldParams} type="number" name="quantity" label="Quantity" value={String(item.quantity)} onChange={event => handleItemChange(event.target.name, event.target.value, index)} /></Grid>
                                                                    </Grid>
                                                                    <Grid container item alignContent="center" xs={1}>
                                                                        <Grid item>
                                                                            <IconButton tabIndex={-1} onClick={() => handleRemoveItem(index)}><CloseIcon /></IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                    :
                                                    <Grid item>
                                                        <Typography variant="body1" sx={{ color: "#495464" }}>No item was added ;-(</Typography>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid container item>
                                                <Grid container item><Button onClick={handleAddItem} sx={{ backgroundColor: "#302b27", color: "#d9d9d9", "&:hover": { backgroundColor: "#302b27" } }}>Add Item</Button></Grid>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                                <Grid container item>
                                    <Grid container item xs={2} alignItems="center">
                                        <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                                    </Grid>
                                    <Grid container item xs={10} justifyContent="flex-end" alignItems="center">
                                        <Grid item sx={{ mr: 2.5 }}>
                                            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                disabled={disableSubmitButton}
                                                sx={{ backgroundColor: "#1b5e7d", color: "#d9d9d9", "&:hover": { backgroundColor: "#1b5e7d" } }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {openCloseDialog && <CloseDialog open={openCloseDialog} setOpen={setOpenCloseDialog} handleClose={handleClose} />}
                            {openDelete && <DeleteDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} type="checkout" />}
                        </form>
                    }
                </div>
            </Paper>
        </Modal>
    );
};

export default EditCheckoutModal;